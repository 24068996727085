export class RbtNode<T> {
    isRed: boolean = true;
    left: RbtNode<T>;
    right: RbtNode<T>;
    parent: RbtNode<T>;
    readonly value: T;

    constructor(val: T) {
        this.value = val;
    }

    grandParent() {
        return this.parent ? this.parent.parent : null;
    }

    sibling() {
        if (this.parent) {
            if (this.isLeftChild()) {
                return this.parent.right;
            } else {
                return this.parent.left;
            }
        } else {
            return null;
        }
    }

    auncle() {
        if (this.parent) {
            return this.parent.sibling();
        } else {
            return null;
        }
    }

    setLeftChild(newChild: RbtNode<T>) {
        this.setChild(newChild, true);
    }

    setRightChild(newChild: RbtNode<T>) {
        this.setChild(newChild, false);
    }

    setChild(newChild: RbtNode<T>, leftChild: boolean) {
        if (newChild) {
            newChild.parent = this;
        }
        if (leftChild) {
            this.left = newChild;
        } else {
            this.right = newChild;
        }
    }

    isLeftChild() {
        return this.parent && this === this.parent.left;
    }

    isRightChild() {
        return this.parent && this === this.parent.right;
    }

    hasRedChild() {
        return (this.left && this.left.isRed) || (this.right && this.right.isRed);
    }

    isLeaf() {
        return !(this.left || this.right);
    }
}

export class RedBlackTree<T> {
    protected comparator: (a: T, b: T) => number;
    protected root: RbtNode<T> = null;
    protected numNodes = 0;

    constructor(c: (a: T, b: T) => number) {
        this.comparator = c;
    }

    contains(value: T) {
        return this._contains((r) => this.comparator(value, r), this.root);
    }

    size() {
        return this.numNodes;
    }

    protected _contains(c: (node: T) => number, root: RbtNode<T>): boolean {
        if (!root) {
            return false;
        }
        const compare = c(root.value);
        if (compare === 0) {
            return true;
        } else if (compare < 0) {
            return this._contains(c, root.left);
        } else {
            return this._contains(c, root.right);
        }
    }

    forEach(f: (entry: T) => any) {
        this._forEach(this.root, f);
    }

    protected _forEach(node: RbtNode<T>, f: (T) => any) {
        if (node) {
            this._forEach(node.left, f);
            f(node.value);
            this._forEach(node.right, f);
        }
    }

    toArray(): T[] {
        const retArray: T[] = [];
        this.addToArray(this.root, retArray);
        return retArray;
    }

    protected addToArray(node: RbtNode<T>, array: T[]) {
        if (node) {
            this.addToArray(node.left, array);
            array.push(node.value);
            this.addToArray(node.right, array);
        }
    }

    remove(value: T) {
        return this.removeNode(this.getNode((n) => this.comparator(value, n), this.root));
    }

    protected removeNode(node: RbtNode<T>) {
        if (!node) {
            return false;
        } else {
            this._removeNode(node);
            this.numNodes -= 1;
            return true;
        }
    }

    protected swapNodes(a: RbtNode<T>, b: RbtNode<T>) {
        const temp = new RbtNode<T>(null);
        this.replace(a, temp);
        this.replace(b, a);
        this.replace(temp, b);
    }

    private replace(original: RbtNode<T>, replacement: RbtNode<T>) {
        replacement.setLeftChild(original.left);
        replacement.setRightChild(original.right);
        replacement.isRed = original.isRed;
        if (original.parent) {
            if (original.isLeftChild()) {
                original.parent.setLeftChild(replacement);
            } else {
                original.parent.setRightChild(replacement);
            }
        } else {
            this.setRoot(replacement);
        }
    }

    protected _removeNode(node: RbtNode<T>) {
        if (!node.isLeaf()) {
            const desc = node.left
                ? this.getRightmostDescendant(node.left)
                : this.getLeftmostDescendant(node.right);
            this.swapNodes(node, desc);
            this._removeNode(node);
        } else {
            this.deleteAndBalance(node);
        }
    }

    protected deleteAndBalance(node: RbtNode<T>) {
        if (node.parent) {
            node.parent.setChild(null, node.isLeftChild());
        } else {
            this.setRoot(null);
        }
        if (!node.isRed) {
            this.balanceAfterDeletion(null, node.parent);
        }
    }

    protected balanceAfterDeletion(node: RbtNode<T>, nodeParent: RbtNode<T>) {
        if (this.root === node || this.isRed(node)) {
            if (node) {
                node.isRed = false;
            }
            return;
        }
        const sibling = nodeParent.left === node ? nodeParent.right : nodeParent.left;
        if (!sibling.isRed && sibling.hasRedChild()) {
            if (sibling.isRightChild() && this.isRed(sibling.right)) {
                sibling.right.isRed = false;
                sibling.isRed = nodeParent.isRed;
                this.rotateLeft(nodeParent);
            } else if (sibling.isRightChild() && this.isRed(sibling.left)) {
                sibling.left.isRed = nodeParent.isRed;
                this.rotateRight(sibling);
                this.rotateLeft(nodeParent);
            } else if (sibling.isLeftChild() && this.isRed(sibling.left)) {
                sibling.left.isRed = false;
                sibling.isRed = nodeParent.isRed;
                this.rotateRight(nodeParent);
            } else {
                sibling.right.isRed = nodeParent.isRed;
                this.rotateLeft(sibling);
                this.rotateRight(nodeParent);
            }
            nodeParent.isRed = false;
        } else if (!sibling.isRed && !sibling.hasRedChild()) {
            sibling.isRed = true;
            if (nodeParent.isRed) {
                nodeParent.isRed = false;
            } else {
                this.balanceAfterDeletion(nodeParent, nodeParent.parent);
            }
        } else if (sibling.isRed) {
            nodeParent.isRed = true;
            sibling.isRed = false;
            if (sibling.isRightChild()) {
                this.rotateLeft(nodeParent);
            } else {
                this.rotateRight(nodeParent);
            }
            this.balanceAfterDeletion(node, nodeParent);
        }
    }

    protected getNode(c: (node: T) => number, root: RbtNode<T>): RbtNode<T> {
        if (!root) {
            return null;
        }
        const compare = c(root.value);
        if (compare === 0) {
            return root;
        } else {
            return this.getNode(c, compare < 0 ? root.left : root.right);
        }
    }

    protected getLeftmostDescendant(node: RbtNode<T>): RbtNode<T> {
        if (!node.left) {
            return node;
        } else {
            return this.getLeftmostDescendant(node.left);
        }
    }

    protected getRightmostDescendant(node: RbtNode<T>): RbtNode<T> {
        if (!node.right) {
            return node;
        } else {
            return this.getRightmostDescendant(node.right);
        }
    }

    add(value: T) {
        return this._addNode(new RbtNode<T>(value));
    }

    protected _addNode(newNode: RbtNode<T>) {
        let valueWasNew = false;
        if (!this.root) {
            this.root = newNode;
            this.root.isRed = false;
            valueWasNew = true;
        } else {
            valueWasNew = this._addNonRootNode(newNode, this.root);
        }
        if (valueWasNew) {
            this.numNodes += 1;
        }
        return valueWasNew;
    }

    protected _addNonRootNode(newNode: RbtNode<T>, root: RbtNode<T>): boolean {
        const compare = this.comparator(newNode.value, root.value);
        if (compare === 0) {
            this.replace(root, newNode);
            return false;
        } else if (compare < 0) {
            if (root.left) {
                return this._addNonRootNode(newNode, root.left);
            } else {
                root.setLeftChild(newNode);
                this.balanceAfterInsert(newNode);
                return true;
            }
        } else {
            if (root.right) {
                return this._addNonRootNode(newNode, root.right);
            } else {
                root.setRightChild(newNode);
                this.balanceAfterInsert(newNode);
                return true;
            }
        }
    }

    protected balanceAfterInsert(inserted: RbtNode<T>) {
        if (!inserted.parent) {
            // If inserted is at the root, mark it as black and return
            inserted.isRed = false;
            return;
        } else if (!inserted.parent.isRed) {
            // No conflict if parent is red
            return;
        } else if (inserted.parent.isRed && this.isRed(inserted.auncle())) {
            // Both of grandparent's children are red. Re-color and recurse.
            inserted.parent.isRed = false;
            inserted.auncle().isRed = false;
            inserted.grandParent().isRed = true;
            this.balanceAfterInsert(inserted.grandParent());
        } else {
            // The parent's node is red, but the parent's sibling's node is black
            if (inserted.isRightChild() && inserted.parent.isLeftChild()) {
                this.rotateLeft(inserted.parent);
                inserted = inserted.left;
            } else if (inserted.isLeftChild() && inserted.parent.isRightChild()) {
                this.rotateRight(inserted.parent);
                inserted = inserted.right;
            }

            const oldGrandparent = inserted.grandParent();
            if (inserted.isRightChild()) {
                this.rotateLeft(inserted.grandParent());
            } else {
                this.rotateRight(inserted.grandParent());
            }
            inserted.parent.isRed = false;
            oldGrandparent.isRed = true;
        }
    }

    protected isRed(node: RbtNode<T>) {
        return node && node.isRed;
    }

    protected setRoot(node: RbtNode<T>) {
        this.root = node;
        if (node) {
            node.parent = null;
            this.root.isRed = false;
        }
    }

    protected rotateLeft(node: RbtNode<T>) {
        const rightChild = node.right;
        const parent = node.parent;
        if (parent) {
            if (node.isLeftChild()) {
                parent.setLeftChild(rightChild);
            } else {
                parent.setRightChild(rightChild);
            }
        } else {
            this.setRoot(rightChild);
        }
        node.setRightChild(rightChild.left);
        rightChild.setLeftChild(node);
    }

    protected rotateRight(node: RbtNode<T>) {
        const leftChild = node.left;
        const parent = node.parent;
        if (parent) {
            if (node.isLeftChild()) {
                parent.setLeftChild(leftChild);
            } else {
                parent.setRightChild(leftChild);
            }
        } else {
            this.setRoot(leftChild);
        }
        node.setLeftChild(leftChild.right);
        leftChild.setRightChild(node);
    }

    protected addToSubtree(node: RbtNode<T>, root: RbtNode<T>) {
        if (this.comparator.call(node, root) < 0) {
            if (root.left) {
                this.addToSubtree(node, root.left);
            } else {
                root.setLeftChild(node);
            }
        } else {
            if (root.right) {
                this.addToSubtree(node, root.right);
            } else {
                root.setRightChild(node);
            }
        }
    }
}
