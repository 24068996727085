import Base = require("Everlaw/Base");

class DocumentGroupFilter extends Base.Object {
    static Attachments = DocumentGroupFilter.init(
        "pluckConversations",
        "Conversations",
        "Attachments",
        3,
    );
    static NoFilter = DocumentGroupFilter.init("flattenGroups", "None", "None (keep all)", 7);
    // "Parent" because each document group only has one parent
    static PluckChildren = DocumentGroupFilter.init("pluckChildren", "Children", "Parent", 1);
    static PluckParent = DocumentGroupFilter.init("pluckParent", "Parent", "Children", 2);
    static GroupedHits = DocumentGroupFilter.init(
        "groupedHits",
        "Grouped non-hits",
        "Search hits",
        4,
    );
    static SearchHits = DocumentGroupFilter.init(
        "searchHits",
        "Search hits",
        "Grouped non-hits",
        0,
    );
    static InclusiveEmails = DocumentGroupFilter.init(
        "inclusiveEmails",
        "Inclusive emails",
        "Non-inclusive emails",
        5,
        "Non-incl. emails",
    );
    static ExactEmailDuplicates = DocumentGroupFilter.init(
        "exactEmailDuplicates",
        "Exact email duplicates",
        "Email duplicates",
        6,
        "Non-exact email dupes",
    );
    private static filterMap = DocumentGroupFilter.generateFilterMap();

    private static init(
        id: string,
        name: string,
        toRemove: string,
        displayOrder: number,
        toRemoveAbbr?: string,
    ) {
        const filter = new DocumentGroupFilter(id, name, toRemove, displayOrder, toRemoveAbbr);
        Base.add(filter);
        return filter;
    }
    get className() {
        return "DocumentGroupFilter";
    }
    override id: string; // EQL_NAME of the corresponding property in Property.ts

    /**
     * toRemove is a displayable string of what docs will be removed if this filter is used.
     * toRemoveAbbr is the abbreviated version of toRemove.
     */
    protected constructor(
        id: string,
        public name: string,
        private toRemove: string,
        public displayOrder: number,
        private toRemoveAbbr?: string,
    ) {
        super({ id });
    }
    override display() {
        return this.name;
    }
    displayRemoved() {
        return this.toRemove;
    }
    displayRemovedAbbr() {
        return this.toRemoveAbbr || this.toRemove;
    }
    override compare(other) {
        if (other.className === "DocumentGroupFilter") {
            return this.displayOrder - other.displayOrder;
        }
        return super.compare(other);
    }
    static getFilterFromName(name: string) {
        if (Object.keys(this.filterMap).includes(name)) {
            return this.filterMap[name];
        }
    }
    private static generateFilterMap() {
        const filterMap = {};
        for (const name of Object.keys(DocumentGroupFilter)) {
            const ref = DocumentGroupFilter[name];
            if (typeof ref == "object" && ref.className === "DocumentGroupFilter") {
                filterMap[ref.displayRemoved()] = ref;
            }
        }
        return filterMap;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module DocumentGroupFilter {
    export type Id = string & Base.Id<"DocumentGroupFilter">;
}

export = DocumentGroupFilter;
